import React from 'react'
import { LayoutWithImage, Layout } from '../layouts'
import SiteTitle from '../components/Blocks/SiteTitle/SiteTitle'
import Hero from '../components/Blocks/Hero/Hero'
import Seo from '../components/seo'
import { CONTENT } from '../content'

const Honoraires = () => (
  <Layout>
    <Seo
      title={CONTENT.FEES.meta.title}
      description={CONTENT.FEES.meta.description}
    />
    <section id="honoraires">
      <SiteTitle />
      <Hero>
        <header>
          <h1>{CONTENT.FEES.title}</h1>
        </header>

        <div>
          {CONTENT.FEES.content.map((content, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: content }} />
          ))}
        </div>

        <div>
          {CONTENT.FEES.disclaimer.map((disclaimer, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: disclaimer }} />
          ))}
        </div>
      </Hero>
    </section>
  </Layout>

  // <LayoutWithImage>
  //   <Seo
  //     title={CONTENT.FEES.meta.title}
  //     description={CONTENT.FEES.meta.description}
  //   />

  //   <div className="wrapper" id="honoraires">
  //   </div>
  // </LayoutWithImage>
)

export default Honoraires
